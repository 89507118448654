<section class="slice slice-lg bg-section-secondary">
  <div class="container text-center">
    <div class="row justify-content-center mb-6">
      <div class="col-lg-8">
        <!-- Title -->
        <h2 class="h1 strong-600">
          Няма такава страница
        </h2>
        <!-- Text -->
        <button class="btn btn-primary" routerLink="/"> Върни се към началото</button>
      </div>
    </div>
  </div>


</section>
