import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UserService} from '../user/user.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingPageComponent implements OnInit {
  get isLogin() {
    return !!this.userService.user;
  }

  freePlan;
  hasAccess = false;
  activePlanTo;

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.userService.authCompleted$.subscribe(user => {
      this.freePlan = user.free_plan === 0;
      this.hasAccess = user.active_plan !== 0;
      this.activePlanTo = user.plan_to;
    });

    this.userService.userLogoutEvent.subscribe(_ => {
      this.freePlan = false;
      this.hasAccess = false;
      this.activePlanTo = false;
    });
  }

}
