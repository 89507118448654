import {Injectable, EventEmitter} from '@angular/core';
import {Observable, of} from 'rxjs';
import {shareReplay, tap} from 'rxjs/operators';
import {User} from './interfaces/user';
import {UserLoginData} from './interfaces/userLoginData';
import {UserRegisterData} from './interfaces/userRegisterData';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: User | undefined | null = undefined;

  // authCompleted$ = this.http.get<User>(`user/auth`).pipe(shareReplay(1));

  authCompleted$ = this.http.get<User>(`user/auth`).pipe(shareReplay(1));
  authCompletedSingle$ = this.http.get<User>(`user/auth`);
  userLogoutEvent = new EventEmitter();

  constructor(private http: HttpClient) {
    this.authCompleted$.subscribe((user: User | any) => {
      this.user = user.error ? null : user;
    });
  }

  register(data: UserRegisterData) {
    return this.http.post(`user/register`, {email: data.email, password: data.password, realName: data.name});
  }

  login(data: UserLoginData) {
    return this.http.post<User>(`user/login`, {email: data.email, password: data.password})
      .pipe(tap((user) => {
        this.user = user;
      }));
  }

  logout() {
    return this.http.get(`user/logout`).pipe(tap(() => {
      this.user = null;
      this.userLogoutEvent.emit(true);
    }));
  }

  getAll(): Observable<User[]> {
    return this.http.get<User[]>('user/getall');
  }

  getById(id) {
    return this.http.get<User>(`user/${id}`);
  }

  delete(id) {
    return this.http.delete<User>(`user/${id}`);
  }

  freeTrial(id) {
    return this.http.post<User>(`free-trial/${id}`, {});
  }
}
