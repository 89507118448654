import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {paypalHelper} from "../helper/paypal";
import {PriceService} from "../price.service";
import {UserService} from "../../user/user.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements OnInit {
  @Input('price') price;
  @Input('name') name;
  @Input('planId') planId;
  @Output('processPayment') processPayment = new EventEmitter<boolean>();

  payPalConfig;

  constructor(private priceService: PriceService, private userService: UserService, private router: Router) {
  }

  ngOnInit(): void {
    this.payPalConfig = paypalHelper(this.priceService, this.userService, this.router, this.startProcess.bind(this), this.endProcess.bind(this))
    (this.price, this.name, this.planId);
  }

  startProcess() {
    this.processPayment.emit(true);
  }

  endProcess() {
    this.processPayment.emit(false);
  }
}
