import {Injectable} from '@angular/core';
import {CanLoad, Route, Router, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError, map} from "rxjs/operators";
import {UserService} from "../../user/user.service";

@Injectable({
  providedIn: 'root'
})
export class UserPlanGuard implements CanLoad {
  constructor(private userService: UserService, private router: Router) {
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.authCompletedSingle$.pipe(map(user => {
      if (user.active_plan === 0) {
        this.router.navigate(['/logged']);
      }

      return user.active_plan !== 0;
    }), catchError((err) => {
      return this.router.navigate(['/user/login']);
    }));
  }
}
