import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from 'src/app/user/user.service';
import { Router } from '@angular/router';
import {ToastService} from "angular-toastify";
import {SettingsService} from "../../admin/settings.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  siteName: any;

  get isLogin() {
    return !!this.userService.user;
  }

  get isAdmin() {
    return this.userService.user?.role === 'admin';
  }

  constructor(private userService: UserService, private router: Router, private toastService: ToastService, private settingsService: SettingsService) { }

  ngOnInit(): void {
    this.settingsService.getById(1).subscribe(value => {
      this.siteName = value;
    });
  }

  logout() {
    this.userService.logout().subscribe(() => {
      this.router.navigate(['']).then();
      this.toastService.info('Успешен изход');
    });
  }

}
