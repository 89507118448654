import { Pipe, PipeTransform } from '@angular/core';
import {PriceService} from "../price.service";
import {Observable} from "rxjs";

@Pipe({
  name: 'pricingPlan'
})
export class PricingPlanPipe implements PipeTransform {

  constructor(private priceService: PriceService) {
  }

  transform(value: number, ...args: unknown[]): Observable<any> {
    return this.priceService.getPriceById(value);
  }

}
