<section class="slice slice-lg bg-section-secondary">
  <div class="container text-center">
    <div class="row justify-content-center mb-6">
      <div class="col-lg-8">
        <!-- Title -->
        <h2 class="h1 strong-600">
          ПРАВИЛА И УСЛОВИЯ </h2>
        <!-- Text -->

      </div>
    </div>
    <div style="text-align: justify">
      <p class="bold">
        СЪГЛАСИЕ С УСЛОВИЯТА
      </p>

      <p>
        Настоящите Условия за ползване представляват правно обвързващо споразумение, сключено между Вас, лично или от
        името
        на юридическо лице („Вие“) и Онлайн Апетка (Компанията, “ние”, “сайта”, “платформата”), относно вашия достъп към
        и
        използването на
        уебсайт pharmacy.com, както и всяка друга медийна форма, медиен канал, мобилен уебсайт или мобилно приложение,
        свързани, свързани или свързани по друг начин с тях (наричани заедно „Сайтът“).
      </p>

      <p>
        Вие се съгласявате, че с достъп до Сайта сте прочели, разбрали и се съгласили да бъдете обвързани с всички тези
        Условия за ползване. АКО НЕ СЪГЛАСНИ С ВСИЧКИ ТЕЗИ УСЛОВИЯ ЗА ПОЛЗВАНЕ, ТОГАВА ВИ ИЗРИЧНО СЕ ЗАБРАНЯВА ДА
        ИЗПОЛЗВАТЕ САЙТА И ТРЯБВА ВЕДНАГА ДА ПРЕКРАТЕТЕ ИЗПОЛЗВАНЕТО МУ.
      </p>

      <p>
        Допълнителни условия или документи, които могат да бъдат публикувани на Сайта от време на време, са изрично
        включени тук чрез препратка. Запазваме си правото, по наше усмотрение, да правим промени или модификации на тези
        Условия за ползване по всяко време и по каквато и да е причина. Ще ви предупредим за всякакви промени, като
        актуализираме датата на „Последна актуализация“ на настоящите Условия за ползване и вие се отказвате от всяко
        право да получавате конкретно известие за всяка такава промяна. Моля, уверете се, че проверявате приложимите
        Условия всеки път, когато използвате нашия Сайт, за да разберете кои Условия се прилагат. Вие ще бъдете обект и
        ще се считате, че сте запознати и че сте приели промените във всички ревизирани Условия за ползване, като
        продължите да използвате Сайта след датата на публикуване на тези ревизирани Условия за ползване.
      </p>

      <p>
        Информацията, предоставена на Сайта, не е предназначена за разпространение до или използване от което и да е
        физическо или юридическо лице в която и да е юрисдикция или държава, където такова разпространение или
        използване би било в противоречие със закон или наредба или което би ни подложило на изискване за регистрация в
        рамките на такава юрисдикция или държава. Съответно тези лица, които избират да получат достъп до Сайта от
        други места, правят това по своя собствена инициатива и са единствената отговорност за спазването на местните
        закони, ако и доколкото местните закони са приложими.
      </p>

      <p>
        Всички потребители, които са непълнолетни в юрисдикцията, в която пребивават (обикновено на възраст под 18
        години), трябва да имат разрешение и да бъдат пряко контролирани от техния родител или настойник, за да
        използват Сайта. Ако сте непълнолетен, трябва да накарате вашия родител или настойник да прочете и да приеме
        настоящите Условия за ползване, преди да използвате Сайта.
      </p>

      <p class="bold">
        ПРАВАТА НА ИНТЕЛЕКТУАЛНА СОБСТВЕНОСТ
      </p>

      <p>Освен ако не е посочено друго, Сайтът е наша собственост и целият изходен код, бази данни, функционалност,
        софтуер, дизайн на уебсайтове, аудио, видео, текст, снимки и графики на Сайта (наричани заедно „Съдържанието“) и
        търговските марки, услуги марките и лога, съдържащи се в тях („Марките“) са собственост или контролирани от нас
        или са ни лицензирани и са защитени от законите за авторско право и търговски марки и различни други права на
        интелектуална собственост и закони за нелоялна конкуренция на Съединените щати, международни закони за авторско
        право, и международни конвенции. Съдържанието и Марките са предоставени на Сайта „КАКТО Е“ само за ваша
        информация и лична употреба. Освен ако изрично е предвидено в настоящите Условия за ползване, никоя част от
        Сайта и никакво Съдържание или Марки не могат да бъдат копирани, възпроизвеждани, обобщавани, препубликувани,
        качвани, публикувани, публично показвани, кодирани, превеждани, предавани, разпространявани, продавани,
        лицензирани или по друг начин се използва за каквато и да е търговска цел, без нашето изрично предварително
        писмено разрешение.
      </p>

      <p>
        При условие, че отговаряте на условията за използване на Сайта, ви се предоставя ограничен лиценз за достъп и
        използване на Сайта, както и за изтегляне или отпечатване на копие на която и да е част от Съдържанието, до
        което сте получили достъп, единствено за вас, без комерсиално използване. Ние си запазваме всички
        права, които не са ви изрично предоставени в и върху Сайта, Съдържанието и Марките.
      </p>

      <p class="bold">
        ПОТРЕБИТЕЛСКИ ПРЕДСТАВЛЕНИЯ
      </p>

      <p>
        Използвайки Сайта, вие заявявате и гарантирате, че:
        (1) цялата регистрационна информация, която изпращате, ще бъде вярна, точна, актуална и пълна; (2) Вие ще
        поддържате точността на тази информация и ще актуализирате своевременно тази регистрационна информация, ако е
        необходимо; (3) имате правоспособност и се съгласявате да спазвате настоящите Условия за ползване; (4) не сте
        непълнолетен в юрисдикцията, в която пребивавате, или ако сте непълнолетни, сте получили родителско разрешение
        да използвате Сайта; (5) няма да осъществявате достъп до Сайта чрез автоматизирани или нечовешки средства,
        независимо дали чрез бот, скрипт или по друг начин; (6) няма да използвате Сайта за незаконни или неразрешени
        цели; и (7) използването от ваша страна на Сайта няма да наруши нито един приложим закон или наредба.
      </p>

      <p>
        Ако предоставите информация, която е невярна, неточна, неактуална или непълна, ние имаме право да спрем или
        прекратим акаунта ви и да откажем всякакво текущо или бъдещо използване на Сайта (или част от него).
      </p>

      <p class="bold">
        РЕГИСТРАЦИЯ НА ПОТРЕБИТЕЛ
      </p>

      <p>
        Може да се наложи да се регистрирате в Сайта. Вие се съгласявате да запазите паролата си поверителна и ще носите
        отговорност за всяко използване на вашия акаунт и парола. Запазваме си правото да премахнем, възстановим или
        променим избрано от вас потребителско име, ако преценим, по наше усмотрение, че това потребителско име е
        неподходящо, нецензурно или по друг начин неприемливо.
      </p>

      <p class="bold">
        ЗАБРАНЕНИ ДЕЙНОСТИ
      </p>

      <p>
        Нямате право да осъществявате достъп или да използвате Сайта за цели, различни от тези, за които предоставяме
        Сайта. Сайтът не може да се използва във връзка с каквито и да било търговски начинания, освен тези, които са
        специално одобрени или одобрени от нас.
      </p>

      <p>
        Като потребител на сайта, вие се съгласявате да не:
        Систематично извличайте данни или друго съдържание от Сайта, за да създадете или компилирате, пряко или непряко,
        колекция, компилация, база данни или директория без писмено разрешение от нас.
        Подмамете, измамете или подведете нас и другите потребители, особено при всеки опит да научите чувствителна
        информация за акаунта, като например потребителски пароли.
        Заобикаляте, деактивирайте или по друг начин пречат на свързаните със сигурността функции на Сайта, включително
        функции, които предотвратяват или ограничават използването или копирането на каквото и да е Съдържание или
        налагат ограничения върху използването на Сайта и/или съдържанието, което се съдържа в него.
        Обезценява, опетнява или по друг начин наранява, по наше мнение, нас и/или Сайта.
        Използвайте всяка информация, получена от Сайта, за да тормозите, злоупотребявате или наранявате друго лице.
        Използвайте неправилно нашите услуги за поддръжка или подавайте фалшиви доклади за злоупотреба или неправомерно
        поведение.
        Използвайте Сайта по начин, който не съответства на приложимите закони или разпоредби.
        Участвайте в неоторизирано рамкиране или свързване към Сайта.
        Качване или предаване (или опит за качване или предаване) на вируси, троянски коне или друг материал,
        включително прекомерна употреба на главни букви и спам (непрекъснато публикуване на повтарящ се текст), което
        пречи на непрекъснатото използване и ползване на сайта от която и да е страна или променя, нарушава, нарушава,
        променя или пречи на използването, функциите, функциите, работата или поддръжката на Сайта.
        Участвайте във всяка автоматизирана употреба на системата, като например използване на скриптове за изпращане на
        коментари или съобщения или използване на каквито и да е данни за извличане на данни, роботи или подобни
        инструменти за събиране и извличане на данни.
        Изтрийте известието за авторски права или други права на собственост от всяко Съдържание.
        Опит да се представите за друг потребител или лице или да използвате потребителското име на друг потребител.
        Качване или предаване (или опит за качване или предаване) на всеки материал, който действа като пасивен или
        активен механизъм за събиране или предаване на информация, включително, без ограничение, ясни формати за обмен
        на графики („gifs“), 1×1 пиксели, уеб грешки, бисквитки , или други подобни устройства (понякога наричани
        „шпионски софтуер“ или „механизми за пасивно събиране“ или „pcms“).
        Пречат, нарушават или създават ненужна тежест върху Сайта или мрежите или услугите, свързани със Сайта.
        Тормозете, дразнете, сплашвайте или заплашвайте някой от нашите служители или агенти, ангажирани с
        предоставянето на която и да е част от Сайта за вас.
        Опит за заобикаляне на всякакви мерки на Сайта, предназначени да предотвратят или ограничат достъпа до Сайта или
        до която и да е част от Сайта.
        Копирайте или адаптирайте софтуера на сайта, включително, но не само Flash, PHP, HTML, JavaScript или друг код.
        Освен в случаите, разрешени от приложимото законодателство, дешифрирайте, декомпилирайте, разглобявайте или
        извършвайте обратно инженерство на всеки от софтуерите, съдържащи или по някакъв начин съставляващи част от
        Сайта.
        Освен ако може да е резултат от стандартна търсачка или използване на интернет браузър, използване, стартиране,
        разработване или разпространение на която и да е автоматизирана система, включително без ограничение всеки паяк,
        робот, помощна програма за измама, скрепер или офлайн четец, който осъществява достъп до сайта, или използване
        или стартиране на неоторизиран скрипт или друг софтуер.
        Използвайте агент за закупуване или агент за закупуване, за да правите покупки на сайта.
        Правете каквото и да е неоторизирано използване на сайта, включително събиране на потребителски имена и/или
        имейл адреси на потребители по електронен или друг начин с цел изпращане на непоискани имейли, или създаване на
        потребителски акаунти по автоматизирани средства или под фалшиви претенции.
        Използвайте Сайта като част от усилията да се конкурирате с нас или по друг начин да използвате Сайта и/или
        Съдържанието за което и да е начинание, генериращо приходи или търговско предприятие.
        Продайте или прехвърлете по друг начин вашия профил.
      </p>

      <p class="bold">
        ГЕНЕРИРАНИ ПОТРЕБИТЕЛИ ПРИНОСИ
      </p>

      <p>
        Сайтът може да ви кани да чатите, да допринасяте или да участвате в блогове, табла за съобщения, онлайн форуми и
        други функции и може да ви предостави възможност да създавате, изпращате, публикувате, показвате, предавате,
        изпълнявате, публикувате, разпространявате, или излъчвайте съдържание и материали до нас или на Сайта,
        включително, но не само текст, писания, видео, аудио, снимки, графики, коментари, предложения или лична
        информация или друг материал (наричани заедно „Приноси“). Приносите могат да бъдат видени от други потребители
        на сайта и чрез уебсайтове на трети страни. Като такъв, всички приноси, които предавате, могат да бъдат
        третирани като неповерителни и непатентни. Когато създавате или предоставяте каквито и да било Приноси, вие по
        този начин заявявате и гарантирате, че:
        Създаването, разпространението, предаването, публичното показване или изпълнението, както и достъпът,
        изтеглянето или копирането на вашите приноси не нарушават и няма да нарушават правата на собственост,
        включително, но не само, авторското право, патента, търговската марка, търговската тайна или морални права на
        всяка трета страна.
        Вие сте създател и собственик на или имате необходимите лицензи, права, съгласия, освобождавания и разрешения за
        използване и упълномощаване на нас, Сайта и другите потребители на Сайта да използваме вашите приноси по какъвто
        и да е начин, предвиден от Сайта и тези Условия за ползване.
        Вие имате писменото съгласие, освобождаване и/или разрешение на всяко отделно лице, което може да бъде
        идентифицирано във вашите Приноси, да използвате името или подобието на всяко такова индивидуално лице, което
        може да бъде идентифицирано, за да позволи включването и използването на вашите Приноси по какъвто и да е начин,
        предвиден от Сайта и настоящите Условия за ползване.
        Вашите приноси не са фалшиви, неточни или подвеждащи.
        Вашият принос не е нежелана или неоторизирана реклама, промоционални материали, пирамидални схеми, верижни
        писма, спам, масови писма или други форми на привличане.
        Вашите приноси не са неприлични, блудствени, похотливи, мръсни, насилствени, тормозещи, клеветнически или по
        друг начин нежелателни (както е определено от нас).
        Вашите приноси не осмиват, подиграват, унижават, сплашват или злоупотребяват с никого.
        Вашите приноси не се използват за тормоз или заплаха (в правния смисъл на тези термини) на друго лице и за
        насърчаване на насилие срещу конкретно лице или класа хора.
        Вашият принос не нарушава нито един приложим закон, наредба или правило.
        Вашият принос не нарушава правата за поверителност или публичност на трета страна.
        Вашите приноси не нарушават приложимия закон относно детската порнография или по друг начин са предназначени да
        защитят здравето или благосъстоянието на непълнолетните.
        Вашият принос не включва никакви обидни коментари, свързани с раса, национален произход, пол, сексуални
        предпочитания или физически увреждания.
        Вашите приноси не нарушават по друг начин и не се свързват с материали, които нарушават която и да е разпоредба
        на настоящите Условия за ползване, или който и да е приложим закон или наредба.
        Всяко използване на Сайта в нарушение на горното нарушава настоящите Условия за ползване и може да доведе, наред
        с други неща, до прекратяване или спиране на Вашите права за използване на Сайта.
      </p>

      <p class="bold">
        ЛИЦЕНЗ ЗА ПРИНОС
      </p>

      <p>
        Като публикувате вашите приноси в която и да е част от сайта
        , вие автоматично предоставяте и заявявате и гарантирате, че имате правото да ни предоставите неограничено,
        неограничено, неотменимо, вечно, неизключително, прехвърляемо, безплатно, напълно платено, световно право и
        лиценз за хостване , използване, копиране, възпроизвеждане, разкриване, продажба, препродаване, публикуване,
        излъчване, презаглавие, архивиране, съхраняване, кеширане, публично изпълнение, публично показване,
        преформатиране, превеждане, предаване, откъс (изцяло или частично) и разпространение на такива приноси
        (включително, без ограничение, вашето изображение и глас) за каквато и да е цел, търговска, рекламна или друга,
        и за подготовка на производни произведения на, или включване в други произведения, такива Приноси, и
        предоставяне и разрешаване на подлицензи на горното. Използването и разпространението може да се осъществи във
        всякакви медийни формати и чрез всякакви медийни канали.
      </p>

      <p>
        Този лиценз ще се прилага за всяка форма, медия или технология, известна сега или разработена по-нататък, и
        включва използването от наша страна на вашето име, име на фирма и име на франчайз, както е приложимо, както и
        всяка от търговските марки, марки за услуги, търговски имена, лога, и лични и търговски изображения, които
        предоставяте. Вие се отказвате от всички морални права във вашите Приноси и гарантирате, че моралните права не
        са били заявени по друг начин във вашите Приноси.
      </p>

      <p>
        Ние не претендираме за собственост върху вашите приноси. Вие запазвате пълната си собственост върху всичките си
        приноси и всички права на интелектуална собственост или други права на собственост, свързани с вашите приноси.
        Ние не носим отговорност за изявления или изявления във вашите приноси, предоставени от вас в която и да е
        област на Сайта. Вие носите изцяло отговорността за вашите приноси към сайта и изрично се съгласявате да ни
        освободите от всякаква отговорност и да се въздържате от всякакви правни действия срещу нас относно вашите
        приноси.
      </p>

      <p>
        Имаме право, по наша собствена и абсолютна преценка, (1) да редактираме, редактираме или по друг начин променяме
        всякакви Приноси; (2) да прекатегоризира всички Приноси, за да ги постави на по-подходящи места в Сайта; и (3)
        да преглеждате или изтривате всякакви Приноси по всяко време и по каквато и да е причина, без предизвестие. Ние
        нямаме задължение да наблюдаваме вашите приноси.
      </p>

      <p class="bold">
        ИЗПЪЛНЕНИЯ
      </p>

      <p>
        Вие потвърждавате и се съгласявате, че всички въпроси, коментари, предложения, идеи, обратна връзка или друга
        информация относно Сайта („Изпращания“), предоставена ни от вас, не са поверителни и ще станат наша единствена
        собственост. Ние притежаваме изключителни права, включително всички права на интелектуална собственост, и ще
        имаме право на неограничено използване и разпространение на тези Изявления за всякакви законни цели, търговски
        или други, без признание или компенсация за вас. С настоящото се отказвате от всички морални права върху подобни
        Изявления и с настоящото гарантирате, че всички такива Изявления са оригинални от вас или че имате право да
        изпращате такива Изявления. Вие се съгласявате, че няма да има иск срещу нас за всяко предполагаемо или
        действително нарушение или незаконно присвояване на права на собственост във вашите Изявления.
      </p>

      <p class="bold">
        УПРАВЛЕНИЕ НА САЙТА
      </p>

      <p>
        Запазваме си правото, но не и задължението: (1) да наблюдаваме Сайта за нарушения на настоящите Условия за
        ползване; (2) предприема подходящи правни действия срещу всеки, който, по наше усмотрение, нарушава закона или
        настоящите Условия за ползване, включително, без ограничение, да докладва такъв потребител на правоприлагащите
        органи; (3) по наше собствено усмотрение и без ограничение, отказваме, ограничаваме достъпа, ограничаваме
        наличността или деактивираме (доколкото е технологично осъществимо) всеки от вашите Приноси или част от тях; (4)
        по наша собствена преценка и без ограничение, известие или отговорност, да премахнем от Сайта или по друг начин
        да деактивираме всички файлове и съдържание, които са с прекомерен размер или по някакъв начин са обременяващи
        за нашите системи; и (5) по друг начин управлява Сайта по начин, предназначен да защити нашите права и
        собственост и да улесни правилното функциониране на Сайта.
      </p>

      <p class="bold">
        ПОЛИТИКА ЗА ПОВЕРИТЕЛНОСТ
      </p>

      <p>
        Ние се грижим за поверителността и сигурността на данните. Използвайки Сайта, вие се съгласявате да бъдете
        обвързани с нашата Политика за поверителност, публикувана на Сайта, която е включена в тези Условия за ползване.
        Моля, имайте предвид, че сайтът се хоства в България. Ако имате достъп до Сайта от който и да е друг регион на
        света със закони или други изисквания, регулиращи събирането, използването или разкриването на лични данни,
        които се различават от приложимите закони в България, след това чрез продължаващото ви използване на сайта вие
        прехвърляте вашите данни към
        България и вие се съгласявате вашите данни да бъдат прехвърляни и обработвани в
        България.
      </p>

      <p class="bold">
        НАРУШЕНИЯ НА АВТОРСКИ ПРАВА
      </p>

      <p>
        Ние уважаваме правата на интелектуална собственост на другите. Ако смятате, че материалите, налични на или чрез
        Сайта, нарушават авторски права, които притежавате или контролирате, моля, незабавно ни уведомете, като
        използвате информацията за контакт, предоставена по-долу („Известие“). Копие от Вашето Уведомление ще бъде
        изпратено на лицето, публикувало или съхранило материала, адресиран в Уведомлението. Моля, имайте предвид, че
        съгласно приложимото законодателство може да носите отговорност за щети, ако направите съществени неверни
        твърдения в Уведомление. По този начин, ако не сте сигурни, че материалът, намиращ се на или към който има
        връзка от Сайта, нарушава вашите авторски права, трябва да помислите първо да се свържете с адвокат.
      </p>

      <p class="bold">
        СРОК И ПРЕКРАТЯВАНЕ
      </p>

      <p>
        Тези Условия за ползване остават в пълна сила и действие, докато използвате Сайта. БЕЗ ОГРАНИЧАВАНЕ НА КАКВО
        ДРУГО ПРЕДОСТАВЯНЕ НА ТЕЗИ УСЛОВИЯ ЗА УПОТРЕБА, НИЕ ЗАПАЗВАМЕ ПРАВОТО ПО НАША СОБСТВЕНА РЕШЕНИЕ И БЕЗ
        УВЕДОМЛЕНИЕ ИЛИ ОТГОВОРНОСТ ДА ОТКАЗВАМ ДОСТЪП ДО САЙТА И ИЗПОЛЗВАНЕ НА САЙТА (ВКЛЮЧИТЕЛНО БЛОКИРАНЕ НА
        ОПРЕДЕЛЕН IP АД) БЕЗ ПРИЧИНА, ВКЛЮЧИТЕЛНО БЕЗ ОГРАНИЧЕНИЕ ЗА НАРУШАВАНЕ НА КАКВО И ДА СЕ ЗАЯВЯВА, ГАРАНЦИЯ ИЛИ
        СОГЛАШЕНИЕ, СЪДЪРЖАЩИ се В ТЕЗИ УСЛОВИЯ ЗА УПОТРЕБА ИЛИ НА ПРИЛОЖИМ ЗАКОН ИЛИ РЕГЛАМЕНТ. МОЖЕМ ДА ПРЕКРАТИМ
        ВАШАТА ИЗПОЛЗВАНЕ ИЛИ УЧАСТИЕ В САЙТА ИЛИ ДА ИЗТРИЙМ
        ВАШИЯТ ПРОФИЛ И
        ВСЯКО СЪДЪРЖАНИЕ ИЛИ ИНФОРМАЦИЯ, КОЯТО ВИЕ ПУБЛИКУВАТЕ ПО ВСЯКО ВРЕМЕ, БЕЗ ПРЕДУПРЕЖДЕНИЕ, ПО НАША СОБСТВЕНА
        РЕШЕНИЕ.
      </p>

      <p>
        Ако прекратим или спрем вашия акаунт по някаква причина, ви е забранено да регистрирате и създавате нов акаунт
        под вашето име, фалшиво или заето име или името на трета страна, дори ако може да действате от името на третата
        парти. В допълнение към прекратяването или спирането на вашия акаунт, ние си запазваме правото да предприемем
        подходящи правни действия, включително, без ограничение, преследване на гражданска, наказателна и съдебна
        защита.
      </p>

      <p class="bold">
        МОДИФИКАЦИИ И ПРЕКЪСВАНИЯ
      </p>

      <p>
        Запазваме си правото да променяме, модифицираме или премахваме съдържанието на Сайта по всяко време или по
        каквато и да е причина по наше усмотрение без предизвестие. Въпреки това, ние нямаме задължение да актуализираме
        каквато и да е информация на нашия сайт. Ние също така си запазваме правото да променяме или прекратяваме целия
        или част от Сайта без предизвестие по всяко време. Ние няма да носим отговорност пред вас или трета страна за
        каквато и да е промяна, промяна на цената, спиране или прекратяване на сайта.
      </p>

      <p>
        Не можем да гарантираме, че Сайтът ще бъде наличен по всяко време. Може да изпитаме хардуерни, софтуерни или
        други проблеми или да се наложи да извършим поддръжка, свързана със Сайта, което да доведе до прекъсвания,
        забавяния или грешки. Запазваме си правото да променяме, преразглеждаме, актуализираме, спираме, прекратяваме
        или по друг начин променяме Сайта по всяко време или по каквато и да е причина без да Ви уведомяваме. Вие се
        съгласявате, че ние не носим никаква отговорност за загуби, щети или неудобства, причинени от вашата
        невъзможност за достъп или използване на Сайта по време на прекъсване или прекратяване на Сайта. Нищо в тези
        Условия за ползване няма да се тълкува като задължаващо да поддържаме и поддържаме Сайта или да предоставяме
        корекции, актуализации или издания във връзка с него.
      </p>

      <p class="bold">
        ПРИЛОЖИМО ПРАВО
      </p>

      <p>
        Тези условия се уреждат и тълкуват съгласно законите на България, и използването на Конвенцията на Организацията
        на обединените нации за договорите за международна продажба на стоки е изрично изключено. Ако обичайното ви
        местопребиваване е в ЕС и сте потребител, вие допълнително притежавате защитата, предоставена ви от
        задължителните разпоредби на законодателството на вашата страна на пребиваване.
        Онлайн Апетка и двамата се съгласявате да се подчините на неизключителната юрисдикция на съдилищата на
        Варна, Варна, което означава, че можете да предявите иск, за да защитите правата си за защита на потребителите
        по отношение на тези Условия за ползване в
        България, или в държавата от ЕС, в която пребивавате.
      </p>

      <p class="bold">
        РЕШАВАНЕ НА СПОРОВЕ
      </p>

      <p>
        Неформални преговори
      </p>

      <p>
        За да ускорите разрешаването и да контролирате разходите за всеки спор, противоречие или иск, свързани с
        настоящите Условия за ползване (всеки „Спор“ и заедно „Спорове“), предявени от вас или от нас (отделно, „Страна“
        и колективно, „Страните“), страните се съгласяват първо да се опитат да договарят всеки Спор (освен тези
        спорове, изрично посочени по-долу) неофициално за най-малко
        тридесет (30)
        дни преди започване на арбитраж. Такива неформални преговори започват след писмено уведомление от едната страна
        до другата страна.
      </p>

      <p>
        Обвързващ арбитраж
      </p>

      <p>
        Всеки спор, произтичащ от взаимоотношенията между страните по този договор, се решава от един арбитър, който ще
        бъде избран в съответствие с Арбитража и вътрешните правила на Европейския арбитражен съд като част от
        Европейския арбитражен център със седалище в Страсбург, и които са в сила към момента на подаване на молбата за
        арбитраж и приемането на тази клауза представлява приемане. Седалището на арбитража е
        Варна
        ,
        България
        . Езикът на производството е
        __________
        . Приложими норми на материалното право са правото на
        България
        .
      </p>

      <p class="bold">
        КОРЕКЦИИ
      </p>

      <p>
        На Сайта може да има информация, която съдържа печатни грешки, неточности или пропуски, включително описания,
        цени, наличност и различна друга информация. Запазваме си правото да коригираме всякакви грешки, неточности или
        пропуски и да променяме или актуализираме информацията на Сайта по всяко време, без предизвестие.
      </p>

      <p class="bold">
        ОПРОВЕРЖЕНИЕ
      </p>

      <p>
        САЙТЪТ СЕ ПРЕДОСТАВЯ НА БАЗА КАТО Е И НАЛИЧНА. ВИЕ СЕ СЪГЛАСЯВАТЕ, ЧЕ ИЗПОЛЗВАНЕТО ОТ ВАШАТА САЙТА И НАШИТЕ
        УСЛУГИ ЩЕ БЪДЕ НА ВАШ СЪОБЩЕН РИСК. В НАЙ-ПЪЛНАТА СТЕПЕН, РАЗРЕШЕНА ОТ ЗАКОНА, НИЕ ОТКАЗВАМЕ ВСИЧКИ ГАРАНЦИИ,
        ИЗРИЧНИ ИЛИ ПОДРАЗНАЧАВАЩИ, ВЪВ ВРЪЗКА СЪС САЙТА И ИЗПОЛЗВАНЕТО ОТ ВАША ГО, ВКЛЮЧИТЕЛНО, БЕЗ ОГРАНИЧЕНИЕ,
        ПОДРАЗНАЧАВАЩИТЕ ГАРАНЦИИ ОТ НИКАКВИ ГАРАНЦИИ ЗА НИКАКВИ ГАРАНЦИИ. НЕ ДАВАМЕ ГАРАНЦИИ ИЛИ ЗАЯВЛЕНИЯ ЗА ТОЧНОСТТА
        ИЛИ ПЪЛНОТАТА НА СЪДЪРЖАНИЕТО НА САЙТА ИЛИ СЪДЪРЖАНИЕТО НА ВСЯКАКВИ УЕБСАЙТОВЕ, СВЪРЗАНИ КЪМ САЙТА И НЯМА ДА
        ПОЕМЕМ НИКАКВА ОТГОВОРНОСТ ИЛИ ОТГОВОРНОСТ ЗА ГРЕШКИ И ОТГОВОРНОСТ ЗА ОТГОВОРНОСТ ЗА ОТГОВОРНОСТ 1 2) ЛИЧНИ
        НАРАНИ ИЛИ ИМУЩЕСТВА ЩЕТИ, ОТ КАКВАТО И ДА Е ХАРАКТЕРИСТИКА, В РЕЗУЛТАТ ОТ ВАШИЯ ДОСТЪП ДО САЙТА И ИЗПОЛЗВАНЕ НА
        САЙТА, (3) ВСЯКАКЪВ НЕОТОРИЗИРАН ДОСТЪП ДО ИЛИ ИЗПОЛЗВАНЕ НА НАШИТЕ СИГУРНИ СЪРВЪРИ И/ИЛИ ВСЯКАКВА ИНФОРМАЦИЯ
        И ВСИЧКИ ИНФОРМАЦИИ СЪХРАНЯВАНИ В ТАМ, (4) ВСЯКО ПРЕКЪСВАНЕ ИЛИ ПРЕКРАТЯВАНЕ НА ПРЕДАВАНЕТО КЪМ САЙТА ИЛИ ОТ
        САЙТА, (5) ВСЯКАКВИ ПРОГРАММИ, ВИРУСИ, ТРОЯНСКИ КОНЕ ИЛИ ПОДОБНИ, КОИТО МОЖЕ ДА БЪДАТ ПРЕДАВАНИ КЪМ ИЛИ ПРЕЗ
        САЙТА ОТ ВСЯКА И ТРЕТА/ТРЕТА 6) ВСЯКАКВИ ГРЕШКИ ИЛИ ПРОПУСКИ В КАКВОТО И ДА Е СЪДЪРЖАНИЕ И МАТЕРИАЛИ ИЛИ ЗА
        КАКВАТО И ДА СЕ ЗАГУБИ ИЛИ ВРЕДИ ОТ КАКЪВ ВИД В РЕЗУЛТАТ ОТ ИЗПОЛЗВАНЕТО НА ВСЯКО СЪДЪРЖАНИЕ, ПУБЛИКУВАНО,
        ПРЕДАВАНО ИЛИ ПО ДРУГИЯ ДОСТЪП ПРЕЗ САЙТА. НИЕ НЕ ГАРАНТИРАМЕ, ОДОБРЯВАМЕ, ГАРАНТИРАМЕ ИЛИ ПОЕМАМЕ ОТГОВОРНОСТ
        ЗА ПРОДУКТ ИЛИ УСЛУГА, РЕКЛАМИРАНИ ИЛИ ПРЕДЛАГАНИ ОТ ТРЕТО ЛИЦА ЧРЕЗ САЙТА, НИКОИ УЕБСАЙТ С ХИПЕРВРЪЗКИ ИЛИ
        КАКВО И КАТО И ДА Е УЕБСАЙТ, КОИТО И ДА СЕ РЕКЛАМА НЕ МОЖЕ ДРУГИ МОБИЛИЗАЦИИ. БЪДЕТЕ СТРАНА ИЛИ ПО КАКВАТО И ДА
        Е ОТГОВОРНОСТ ЗА МОНИТОРИНГ НА ВСЯКАКВА ТРАНЗАКЦИЯ МЕЖДУ ВАС И ВСЯКАКВИ ТРЕТИ СТРАНИ ДОСТАВЧИЦИ НА ПРОДУКТИ ИЛИ
        УСЛУГИ. КАТО ПРИ ЗАКУПУВАНЕТО НА ПРОДУКТ ИЛИ УСЛУГА ЧРЕЗ КАКВАТО И ДА Е СРЕДА ИЛИ В КАКВАТО И ДА СРЕДА, ТРЯБВА
        ДА ИЗПОЛЗВАТЕ НАЙ-ДОБРАТА СИ ПРЕЦЕНКА И ДА ПРОДЪЛЖИТЕ ВНИМАНИЕ, КЪДЕТО Е ОТНОСНО.
      </p>

      <p class="bold">
        ОГРАНИЧЕНИЯ НА ОТГОВОРНОСТТА
      </p>

      <p>
        В НИКАКЪВ СЛУЧАЙ НИЕ ИЛИ НАШИТЕ ДИРЕКТОРИ, СЛУЖИТЕЛИ ИЛИ АГЕНТИ НЯМА ДА НОСИМ ОТГОВОРНОСТ ПРЕД ВАС ИЛИ ТРЕТА
        СТРАНА ЗА КОЯТО И ДА Е ПРЯКА, НЕПРЯКА, ПОСЛЕДВАЩА, ПРИМЕРНА, СЛУЧАЙНА, СПЕЦИАЛНА, СПЕЦИАЛНА, ДАННА, ДАННА ИЛИ
        ИЛИ ДРУГИ ЩЕТИ, ВИЗИРАЩИ ОТ ИЗПОЛЗВАНЕТО НА САЙТА ОТ ВАША, ДОРИ АКО НИ БИМ УВЕДЕНИ ЗА ВЪЗМОЖНОСТТА ОТ ТАКИВА
        ЩЕТИ.
        ВЪВ ВСИЧКО ЗА ПРОТИВНОТО, СЪДЪРЖАНО ТУК, НАШАТА ОТГОВОРНОСТ КЪМ ВАС ЗА КАКВАТО И ДА И НЯКАКВА ПРИЧИНА И
        НЕЗАВИСИМО ОТ ФОРМАТА НА ДЕЙСТВИЕТО, ПО ВСЕКИ ВРЕМЕ ЩЕ БЪДЕ ОГРАНИЧЕНА ДО
        СУМАТА, ПЛАЩЕНА, АКО ИМА ИМА, ОТ ВАС НА НАС
        ПО ВРЕМЕ НА
        ШЕСТ (6)
        МЕСЕЧЕН ПЕРИОД ПРЕДИ ВСЯКАКВА КАТО И ДА ВЪЗСТАНЕ ПРИЧИНА ЗА ДЕЙСТВИЕ
        . НЯКОИ ДЪРЖАВНИ ЗАКОНИ НА САЩ И МЕЖДУНАРОДНО ЗАКОНОДАТЕЛСТВО НЕ ПОЗВОЛЯВАТ ОГРАНИЧЕНИЯ НА ПОДРАЗНАЧЕНИ ГАРАНЦИИ
        ИЛИ ИЗКЛЮЧВАНЕ ИЛИ ОГРАНИЧАВАНЕ НА ОПРЕДЕЛЕНИ ЩЕТИ. АКО ТЕЗИ ЗАКОНИ СЕ ПРИЛАГАТ ЗА ВАС, НЯКОИ ИЛИ ВСИЧКИ ПО-ГОРЕ
        ОТКАЗИ ОТ ОТГОВОРНОСТ ИЛИ ОГРАНИЧЕНИЯ МОЖЕ ДА НЕ СЕ ПРИЛАГАТ ЗА ВАС И МОЖЕ ДА ИМАТЕ ДОПЪЛНИТЕЛНИ ПРАВА.
      </p>

      <p class="bold">
        ОБЕЗЩЕТЕНИЕ
      </p>

      <p>
        Вие се съгласявате да защитавате, обезщетявате и ни държите безвредни, включително нашите дъщерни дружества,
        филиали и всички наши съответни служители, агенти, партньори и служители, от и срещу всяка загуба, щета,
        отговорност, иск или искане, включително разумни адвокати ' такси и разходи, направени от трета страна поради
        или произтичащи от:
        (1) вашите приноси;
        (2) използване на Сайта; (3) нарушаване на настоящите Условия за ползване; (4) всяко нарушение на вашите
        декларации и гаранции, посочени в настоящите Условия за ползване; (5) нарушаване от ваша страна на правата на
        трета страна, включително, но не само, права на интелектуална собственост; или (6) всяко явно вредно действие
        спрямо всеки друг потребител на Сайта, с когото сте се свързали чрез Сайта. Независимо от горното, ние си
        запазваме правото, за ваша сметка, да поемем изключителната защита и контрол върху всеки въпрос, за който се
        изисква да ни обезщетите, и вие се съгласявате да сътрудничите, за ваша сметка, при нашата защита от такива
        искове. Ще положим разумни усилия, за да ви уведомим за всеки такъв иск, действие или процедура, която подлежи
        на това обезщетение, след като разберем за това.
      </p>

      <p class="bold">
        ПОТРЕБИТЕЛСКИ ДАННИ
      </p>

      <p>
        Ние ще поддържаме определени данни, които предавате на Сайта с цел управление на работата на Сайта, както и
        данни, свързани с използването на Сайта от ваша страна. Въпреки че извършваме редовно рутинно архивиране на
        данни, вие сте изцяло отговорни за всички данни, които предавате или които се отнасят до всяка дейност, която
        сте предприели с помощта на Сайта. Вие се съгласявате, че ние няма да носим отговорност пред вас за каквато и да
        е загуба или повреда на такива данни и с настоящото се отказвате от всяко право на иск срещу нас, произтичащо от
        такава загуба или повреда на такива данни.
      </p>

      <p class="bold">
        ЕЛЕКТРОННИ КОМУНИКАЦИИ, ТРАНЗАКЦИИ И ПОДПИСИ
      </p>

      <p>
        Посещението на сайта, изпращането на имейли и попълването на онлайн формуляри представляват електронни
        комуникации. Вие се съгласявате да получавате електронни съобщения и се съгласявате, че всички споразумения,
        известия, оповестявания и други комуникации, които ви предоставяме по електронен път, по имейл и на Сайта,
        отговарят на всяко законово изискване тази комуникация да бъде в писмена форма. С НАСТОЯЩОТО ВИЕ ПРИЕМАТЕ
        ИЗПОЛЗВАНЕТО НА ЕЛЕКТРОННИ ПОДПИСИ, ДОГОВОРИ, ПОРЪЧКИ И ДРУГИ ЗАПИСИ, И ЗА ЕЛЕКТРОННО ДОСТАВЯНЕ НА СЪОБЩЕНИЯ,
        ПОЛИТИКИ И ЗАПИСИ ЗА ТРАНЗАКЦИИ, ИНИЦИРАНИ ИЛИ ЗАВЪРШЕНИ ОТ НАС. С настоящото се отказвате от всякакви права или
        изисквания по който и да е устав, наредби, правила, наредби или други закони в която и да е юрисдикция, които
        изискват оригинален подпис или доставка или запазване на неелектронни записи, или за плащания или предоставяне
        на кредити по всякакви други начини отколкото електронни средства.
      </p>

      <p class="bold">
        РАЗНИ
      </p>

      <p>
        Тези Условия за ползване и всякакви политики или оперативни правила, публикувани от нас на Сайта или по
        отношение на Сайта, представляват цялото споразумение и разбирателство между вас и нас. Неуспехът ни да упражним
        или наложим някое право или клауза от тези Условия за ползване няма да действа като отказ от такова право или
        разпоредба. Тези Условия за ползване действат в най-пълната степен, разрешена от закона. Можем да прехвърлим
        някои или всички наши права и задължения на други по всяко време. Ние не носим отговорност за каквито и да било
        загуби, щети, забавяне или бездействие, причинени от причина извън нашия разумен контрол. Ако някоя разпоредба
        или част от разпоредба от настоящите Условия за ползване бъде определена като незаконна, нищожна или
        неприложима, тази разпоредба или част от разпоредбата се счита за отделна от настоящите Условия за ползване и не
        засяга валидността и приложимостта на останалите провизии. Няма съвместно предприятие, партньорство, трудово
        правоотношение или посредничество, създадено между вас и нас в резултат на настоящите Условия за ползване или
        използване на Сайта. Вие се съгласявате, че тези Условия за ползване няма да бъдат тълкувани срещу нас поради
        това, че сте ги изготвили. С настоящото се отказвате от всякакви защити, които може да имате въз основа на
        електронната форма на настоящите Условия за ползване и липсата на подпис от страните за изпълнение на тези
        Условия за ползване.
      </p>

      <p class="bold">
        СВЪРЖЕТЕ СЕ С НАС
      </p>

      <p>
        За да разрешите жалба относно Сайта или да получите допълнителна информация относно използването на Сайта, моля
        свържете се с нас на:

        Онлайн Апетка
        Варна
        Варна
        Варна
        ,
        Варна
        9000
        България
        телефон:
        __________
        dimitar.plamenov@gmail.com
      </p>
    </div>
  </div>


</section>
