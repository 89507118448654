import {ICreateOrderRequest, IPayPalConfig} from 'ngx-paypal';
import {PriceService} from "../price.service";
import {UserService} from "../../user/user.service";
import {Router} from "@angular/router";

export function paypalHelper(service: PriceService, userService: UserService, router: Router, start, end): Function {
  return function (price, name, planId): IPayPalConfig {
    return {
      currency: 'USD',
      clientId: 'sb',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',
              value: price,
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: price
                }
              }
            },
            items: [
              {
                name: name,
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'USD',
                  value: price,
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        start();
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        userService.authCompletedSingle$.subscribe(user => {
          const authToken = user.auth_token.split('')
            .slice(5)
            .join('');

          const token = '2802719220' + authToken + user.active_plan;
          // console.log(user);
          service.setPlan(planId, token, name, price).subscribe(user => {
            end();
            router.navigateByUrl('/').then(r => {
              router.navigateByUrl('/logged');
            });
          });
        });

      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };
  }
}
