import {Component, OnInit} from '@angular/core';
import {SettingsService} from "../../admin/settings.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  aboutUs: any = '';

  constructor(private settingsService: SettingsService) {
  }

  ngOnInit(): void {
    this.settingsService.getById(3).subscribe(value => {
      this.aboutUs = value;
    });
  }
}
