import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ToastService} from "angular-toastify";

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
  constructor(private http: HttpClient, private router: Router, private toastService: ToastService) {
  }

  ngOnInit(): void {
  }

  submitHandler(data: any) {
    this.http.post('contact', data).subscribe(data => {
      this.router.navigateByUrl('/');
      this.toastService.info('Успешно изпратено');
    });
  }
}
