<section class="slice slice-lg pt-lg-6 pb-0 pb-lg-6 bg-section-secondary" id="about-us">
    <div class="container">
        <!-- Title -->
        <!-- Section title -->
        <div class="row mb-5 justify-content-center text-center">
            <div class="col-lg-6">
                <!-- <span class="badge badge-soft-success badge-pill badge-lg">
                    Welcome        
                </span> -->
                <h2 class="mt-4">Кои сме ние?</h2>
            </div>
        </div>
        <!-- Card -->
        <div class="row mt-5">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body pb-5">
                        <div class="pt-4 pb-5">
                            <img src="assets/img/svg/illustrations/illustration-5.svg" class="img-fluid img-center" style="height: 150px;" alt="Illustration" />
                        </div>
                        <h5 class="h4 lh-130 mb-3">10 аптеки</h5>
                        <p class="text-muted mb-0">
                            Заедно успяхме да разработим 10 аптеки. Всички наши обекти работят без почивен ден.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body pb-5">
                        <div class="pt-4 pb-5">
                            <img src="assets/img/svg/illustrations/illustration-6.svg" class="img-fluid img-center" style="height: 150px;" alt="Illustration" />
                        </div>
                        <h5 class="h4 lh-130 mb-3">30 години опит</h5>
                        <p class="text-muted mb-0">В сферата на фармацията сме от 1991г., тогава отворихме нашата първа аптека в гр.Варна.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body pb-5">
                        <div class="pt-4 pb-5">
                            <img src="assets/img/svg/illustrations/illustration-7.svg" class="img-fluid img-center" style="height: 150px;" alt="Illustration" />
                        </div>
                        <h5 class="h4 lh-130 mb-3">Разрастване в Шумен</h5>
                        <p class="text-muted mb-0">Успешно се разрастваме и в други градове. През 2017г. отворихме аптека за пръв път в друг град. Сега наши цели са Бургас, Добрич и Русе.</p>
                    </div>
                </div>
            </div>
        </div>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eros ex, condimentum sit amet venenatis eget, egestas vitae dui. Pellentesque orci risus, vestibulum sit amet malesuada et, mattis at libero. Vivamus luctus tellus mi, eu pretium erat aliquet eu. Nunc vel cursus leo. Aliquam in laoreet urna. Nulla ac interdum justo, lacinia luctus purus.
        </p>
    </div>
</section>
