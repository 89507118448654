import {Component, OnInit, Input} from '@angular/core';
import {UserService} from "../user/user.service";
import {User} from "../user/interfaces/user";
import {PriceService} from "../core/price.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})
export class PricesComponent implements OnInit {
  @Input('hasTitle') hasTitle: boolean = true;
  user: User | undefined;
  isProcessingPayment = false;
  isBankDataShow = false;
  ground = '';
  price = 0;
  freePlan;

  get isLogin() {
    return !!this.userService.user;
  }

  constructor(private userService: UserService, private priceService: PriceService, private router: Router) {
  }

  ngOnInit() {
    this.userService.authCompletedSingle$.subscribe(user => {
      this.user = user;
      this.freePlan = user.free_plan === 0;
    });
  }

  processPaymentHandler(event) {
    this.isProcessingPayment = event;
  }

  openBankInfo(e, ground, price) {
    e.preventDefault();
    this.isBankDataShow = true;
    this.ground = ground;
    this.price = price;
  }

  closeBankInfo() {
    this.isBankDataShow = false;
  }

  activeFreeTrial(e) {
    e.preventDefault();
    this.priceService.freeTrial().subscribe(_ => {
      this.router.navigateByUrl('/').then(r => {
        this.router.navigateByUrl('/logged');
      });
    });
  }
}
