<section class="slice slice-lg bg-section-secondary" id="price">
  <div class="container text-center">
    <div class="row justify-content-center mb-6" *ngIf="hasTitle">
      <div class="col-lg-8">
        <!-- Title -->
        <h2 class="h1 strong-600">
          Сравни! Направи своя избор!
        </h2>
        <!-- Text -->
        <p class="text-muted">
          Ще имаш достъп до статии на нашия сайт.
        </p>
      </div>
    </div>
    <h4 style="color: red; font-weight: bold" class="mb-5" *ngIf="freePlan && hasTitle">Можеш да заявиш 10-дневен пробен период</h4>

    <!-- Pricing -->
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md">
        <div class="card card-pricing text-center px-3 hover-scale-110">
          <div class="card-header py-5 border-0 delimiter-bottom">
            <span class="h1" style="color:#006ecc">План сребро</span>
            <div class="h5 text-center mb-0">BGN<span class="price font-weight-bolder">5.39</span></div>
            <span class="h6">= 2 прегледа при личен лекар.</span>
          </div>
          <div class="card-body">
            <h3>Получаваш: </h3>
            <ul class="list-unstyled text-sm mb-4">
              <li class="py-2">достъп до 3 статии</li>
              <li class="py-2">2 въпроса с отговор до 12часа</li>
            </ul>
            <ng-container *ngIf="user">
              <ng-container *ngIf="user.active_plan === 0">
                <app-paypal [price]="5.39" name="план сребро 1 месец абонамент" [planId]="1"
                            (processPayment)="processPaymentHandler($event)"></app-paypal>
                <p>или <a (click)="openBankInfo($event, 'абонамент план сребро', '5.39')" href="#">банков превод</a></p>
              </ng-container>
              <p style="font-size: 22px; font-weight: bold" class="mb-0" *ngIf="user.active_plan === 1">текущ план</p>
              <p *ngIf="user.active_plan === 1">{{user.plan_to | formatDate: true}}</p>
            </ng-container>
            <button class="btn btn-primary" *ngIf="!isLogin" routerLink="/user/register">Регистрирай се</button>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md">
        <div class="card card-pricing bg-dark-yellow text-center px-3 border-0 hover-scale-110">
          <div class="card-header py-5 border-0 delimiter-bottom pl-0 pr-0">
            <a style="display: block; font-size: 18px; color: white; text-decoration: underline" href="#" *ngIf="freePlan" (click)="activeFreeTrial($event)">активирай пробен период</a>
            <span class="h1" style="color:white;">План платина</span>
            <div class="h5 text-white text-center mb-0">BGN<span class="price font-weight-bolder">23.89</span></div>
            <span class="h6" style="color:white;">= 8 прегледа при личен лекар.</span>
          </div>
          <div class="card-body">
            <h3 style="color: white">Получаваш: </h3>
            <ul class="list-unstyled text-white text-sm opacity-8 mb-4">
              <li class="py-2">достъп до всички статии</li>
              <li class="py-2">онлайн чат в работно време</li>
              <li class="py-2">5% отстъпка в аптеки Кактус</li>
            </ul>
            <ng-container *ngIf="user">
              <ng-container *ngIf="user.active_plan === 0">
                <app-paypal [price]="23.89" name="план платина 1 месец абонамент" [planId]="3"
                            (processPayment)="processPaymentHandler($event)"></app-paypal>
                <p style="color: white">или <a (click)="openBankInfo($event, 'абонамент план платина', '23.89')"
                                               style="text-decoration: underline; cursor: pointer">банков превод</a></p>
              </ng-container>
              <p style="font-size: 22px; font-weight: bold; color: white" class="mb-0" *ngIf="user.active_plan === 3">
                текущ план</p>
              <p *ngIf="user.active_plan === 3;" style="color: white">{{user.plan_to | formatDate: true}}</p>
            </ng-container>
            <button class="btn btn-light" *ngIf="!isLogin" routerLink="/user/register">Регистрирай се</button>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md">
        <div class="card card-pricing text-center px-3 hover-scale-110">
          <div class="card-header py-5 border-0 delimiter-bottom">
            <span class="h1" style="color:#006ecc">План злато</span>
            <div class="h5 text-center mb-0">BGN<span class="price font-weight-bolder">13.99</span></div>
            <span class="h6">= 5 прегледа при личен лекар.</span>
          </div>
          <div class="card-body">
            <h3>Получаваш: </h3>
            <ul class="list-unstyled text-sm mb-4">
              <li class="py-2">достъп до 10 статии</li>
              <li class="py-2">5 въпроса с отговор до 12часа</li>
            </ul>
            <ng-container *ngIf="user">
              <ng-container *ngIf="user.active_plan === 0">
                <app-paypal [price]="13.99" name="план злато 1 месец абонамент" [planId]="2"
                            (processPayment)="processPaymentHandler($event)"></app-paypal>
                <p>или <a (click)="openBankInfo($event, 'абонамент план злато', '13.99')" href="#">банков превод</a></p>
              </ng-container>

              <p style="font-size: 22px; font-weight: bold" class="mb-0"
                 *ngIf="user.active_plan === 2">{{user.plan_to | formatDate: true}}</p>
              <p *ngIf="user.active_plan === 2">до дата</p>
            </ng-container>
            <button class="btn btn-primary" *ngIf="!isLogin" routerLink="/user/register">Регистрирай се</button>
          </div>
        </div>
      </div>
      <table class="table">
        <thead>
        <tr>
          <th>#</th>
          <th>План сребро</th>
          <th>План платина</th>
          <th>План злато</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Достъп до статии</td>
          <td>3</td>
          <td>неограничен брой</td>
          <td>10</td>
        </tr>
        <tr>
          <td>Задай въпрос на фармацевт</td>
          <td>2</td>
          <td>неограничен брой</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Личен фармацевт</td>
          <td>не</td>
          <td>да</td>
          <td>не</td>
        </tr>
        <tr>
          <td>Онлайн чат</td>
          <td>не</td>
          <td>да</td>
          <td>да</td>
        </tr>
        <tr>
          <td>Предлагане на статия</td>
          <td>не</td>
          <td>да</td>
          <td>не</td>
        </tr>
        <tr class="bold">
          <td>Цена</td>
          <td>5.39</td>
          <td>23.89</td>
          <td>13.99</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-5 text-center">
      <p class="mb-2">
        Всички цени са на месец. Ако имате нужда от повече акаунти свържете се с нас за оферта.
      </p>
      <p class="mb-2">
        Възможност за плащане чрез paypal и банков превод.
      </p>
      <a routerLink="/contact" class="text-primary text-underline--dashed">Контакти</a>
    </div>
  </div>

</section>
<div id="overlay" *ngIf="isProcessingPayment">
  <h1>Обработване на плащането...</h1>
</div>

<div id="overlay" *ngIf="isBankDataShow">
  <h1>Данни за превод</h1>
  <p>
    <span class="bold">IBAN: </span>
    BG31UNCR70001523386840
  </p>
  <p>
    <span class="bold">BIC: </span>
    UNCRBGSF
  </p>
  <p>
    <span class="bold">Основание: </span>
    <span [innerText]="ground"></span>
    <span> - </span>
    <span [innerText]="user?.email"></span>
  </p>
  <p>
    <span class="bold">Титуляр: </span>
    Димитър Димитров
  </p>
  <p>
    <span class="bold">Сума: </span>
    <span [innerText]="price"></span>лв
  </p>
  <p><span class="bold">Планът ще бъде активиран до 5часа след получаване на паричния превод.</span></p>
  <button class="btn btn-primary" (click)="closeBankInfo()">Добре</button>
</div>
