import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {LoggedActivateGuard} from './core/guards/logged-activate.guard';
import {HomePageComponent} from './home-page/home-page.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {ServicesPageComponent} from './services-page/services-page.component';
import {PricesComponent} from './prices/prices.component';
import {ContactPageComponent} from './contact-page/contact-page.component';
import {AdminGuard} from "./admin.guard";
import {UserPlanGuard} from "./core/guards/user-plan.guard";
import {TermsComponent} from "./terms/terms.component";
import {NotFoundComponent} from "./core/not-found/not-found.component";

const routes: Routes = [
  {path: "", pathMatch: "full", component: LandingPageComponent},
  {path: "logged", pathMatch: "full", component: HomePageComponent, canActivate: [LoggedActivateGuard]},
  {path: "user", loadChildren: () => import('./user/user.module').then(m => m.UserModule)},
  {
    path: "article",
    loadChildren: () => import('./article/article.module').then(m => m.ArticleModule),
    canLoad: [UserPlanGuard]
  },
  {path: "admin", loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canLoad: [AdminGuard]},
  {
    path: "place-question",
    loadChildren: () => import('./place-question/place-question.module').then(m => m.PlaceQuestionModule),
    canLoad: [UserPlanGuard]
  },
  {path: "about-us", component: AboutUsComponent},
  {path: "services", component: ServicesPageComponent},
  {path: "prices", component: PricesComponent},
  {path: "contact", component: ContactPageComponent},
  {path: "terms", component: TermsComponent},
  {path: "**", component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
