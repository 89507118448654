<footer class="position-relative" id="footer-main">
  <div class="footer pt-lg-7 footer-dark bg-dark">
    <div class="shape-container shape-line shape-position-top shape-orientation-inverse">
      <svg width="2560px" height="100px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 2560 100" style="enable-background:new 0 0 2560 100;"
           xml:space="preserve" class=" fill-section-secondary">
                    <polygon points="2560 0 2560 100 0 100"></polygon>
                </svg>
    </div>
    <!-- Footer -->
    <div class="container pt-4">
      <div class="row">
        <div class="col-lg-4 mb-5 mb-lg-0">
          <!-- Theme's logo -->
          <a href="index.html" class="footer-logo-text-a">
            Онлайн аптека
          </a>
          <!-- Webpixels' mission -->
          <p class="mt-4 text-sm opacity-8 pr-lg-4">
            {{aboutUs.value}}
          </p>
          <!-- Social -->
          <ul class="nav mt-4">
            <li class="nav-item">
              <a class="nav-link pl-0" href="https://dribbble.com/webpixels" target="_blank">
                <i class="fab fa-dribbble"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://github.com/webpixels" target="_blank">
                <i class="fab fa-github"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://www.instagram.com/webpxs" target="_blank">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://www.facebook.com/webpixels" target="_blank">
                <i class="fab fa-facebook"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-6 col-sm-4 ml-lg-auto mb-5 mb-lg-0">
          <h6 class="heading mb-3">Акаунт</h6>
          <ul class="list-unstyled">
            <li><a routerLink="/user/profile">Профил</a></li>
          </ul>
        </div>
        <div class="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
          <h6 class="heading mb-3">За нас</h6>
          <ul class="list-unstyled">
            <li><a routerLink="about-us">Кои сме ние?</a></li>
            <li><a routerLink="contact">Контакти</a></li>

          </ul>
        </div>
        <!-- <div class="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
            <h6 class="heading mb-3">Company</h6>
            <ul class="list-unstyled">
                <li><a href="#">Community</a></li>
                <li><a href="#">Help center</a></li>
                <li><a href="#">Support</a></li>
            </ul>
        </div> -->
      </div>
      <hr class="divider divider-fade divider-dark my-4">
      <div class="row align-items-center justify-content-md-between pb-4">
        <div class="col-md-6">
          <div class="copyright text-sm font-weight-bold text-center text-md-left">
            &copy; 2021 <a href="https://pharmacy.sharkdev.eu" class="font-weight-bold" target="_blank">Онлайн Аптека
            </a>. All rights reserved
          </div>
        </div>
        <div class="col-md-6">
          <ul class="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
            <li class="nav-item">
              <a class="nav-link" routerLink="/terms">
                Условия за ползване
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
