import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) {
  }

  getSettings() {
    return this.http.get('settings');
  }

  updateSettings(settings) {
    return this.http.post('settings', {settings});
  }

  getById(id) {
    return this.http.get(`settings/${id}`);
  }
}
