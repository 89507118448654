<!-- Main content -->
<section class="slice py-7">
  <div class="container">
    <div class="row row-grid align-items-center">
      <div class="col-12 col-md-5 col-lg-6 order-md-2 text-center">
        <!-- Image -->
        <figure class="w-100">
          <i class="fas fa-user-nurse" style="font-size: 500px; color: #0075d9;"></i>
          <!-- <img alt="Image placeholder" src="assets/img/svg/illustrations/illustration-3.svg" class="img-fluid mw-md-120"> -->
        </figure>
      </div>
      <div class="col-12 col-md-7 col-lg-6 order-md-1 pr-md-5">
        <!-- Heading -->
        <h1 class="display-4 text-center text-md-left mb-3">
          Онлайн <strong class="text-primary">аптека</strong> с възможност за връзка с фармацевт
        </h1>
        <!-- Text -->
        <p class="lead text-center text-md-left text-muted">
          Първата онлайн аптека в България
        </p>
        <!-- Buttons -->
        <div class="text-center text-md-left mt-5">
          <a routerLink="/prices" class="btn btn-primary btn-icon" *ngIf="!hasAccess">
            <span class="btn-inner--text">Абонирай се</span>
            <span class="btn-inner--icon"><i data-feather="chevron-right"></i></span>
          </a>
          <a routerLink="/user/register" class="btn btn-neutral btn-icon d-none d-lg-inline-block"
             target="_blank" *ngIf="!hasAccess">10дни пробен период</a>
        </div>
        <ng-container *ngIf="hasAccess">
          <p class="lead text-center text-md-left text-muted">
            Активен план до {{activePlanTo | formatDate: true}}.
          </p>
          <p>
            За достъп до услугите избери <a routerLink="/logged">Начало от менюто</a>.
          </p>
        </ng-container>
      </div>
    </div>
  </div>
</section>
<section class="slice slice-lg pt-lg-6 pb-0 pb-lg-6 bg-section-secondary" id="about-us">
  <div class="container">
    <!-- Title -->
    <!-- Section title -->
    <div class="row mb-5 justify-content-center text-center">
      <div class="col-lg-6">
        <!-- <span class="badge badge-soft-success badge-pill badge-lg">
            Welcome
        </span> -->
        <h2 class="mt-4">Кои сме ние?</h2>
        <div class="mt-2">
          <p class="lead lh-180">
            Първата онлайн аптека в България
          </p>
        </div>
      </div>
    </div>
    <!-- Card -->
    <div class="row mt-5">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body pb-5">
            <div class="pt-4 pb-5">
              <img src="assets/img/svg/illustrations/illustration-5.svg" class="img-fluid img-center"
                   style="height: 150px;" alt="Illustration"/>
            </div>
            <h5 class="h4 lh-130 mb-3">10 аптеки</h5>
            <p class="text-muted mb-0">
              Заедно успяхме да разработим 10 аптеки. Всички наши обекти работят без почивен ден.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body pb-5">
            <div class="pt-4 pb-5">
              <img src="assets/img/svg/illustrations/illustration-6.svg" class="img-fluid img-center"
                   style="height: 150px;" alt="Illustration"/>
            </div>
            <h5 class="h4 lh-130 mb-3">30 години опит</h5>
            <p class="text-muted mb-0">В сферата на фармацията сме от 1991г., тогава отворихме нашата първа аптека в
              гр.Варна.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body pb-5">
            <div class="pt-4 pb-5">
              <img src="assets/img/svg/illustrations/illustration-7.svg" class="img-fluid img-center"
                   style="height: 150px;" alt="Illustration"/>
            </div>
            <h5 class="h4 lh-130 mb-3">Разрастване в Шумен</h5>
            <p class="text-muted mb-0">Успешно се разрастваме и в други градове. През 2017г. отворихме аптека за пръв
              път в друг град. Сега наши цели са Бургас, Добрич и Русе.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="slice slice-lg" id="services">
  <div class="container">
    <div class="py-6">
      <div class="row row-grid justify-content-between align-items-center">
        <div class="col-lg-5 order-lg-2">
          <h5 class="h3">Достъп до статии</h5>
          <p class="lead my-4">
            Нашити статии са подходящи както за пациенти, така и за фармацевти, лекари и студенти.
          </p>
          <ul class="list-unstyled mb-0">
            <li class="py-2">
              <div class="d-flex align-items-center">
                <div>
                  <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3">
                    <i class="fas fa-file-invoice"></i>
                  </div>
                </div>
                <div>
                  <span class="h6 mb-0">Нова статия през ден</span>
                </div>
              </div>
            </li>
            <li class="py-2">
              <div class="d-flex align-items-center">
                <div>
                  <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3">
                    <i class="fas fa-store-alt"></i>
                  </div>
                </div>
                <div>
                  <span class="h6 mb-0">Интервюта с лекари</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-lg-6 order-lg-1">
          <div class="card mb-0 mr-lg-5">
            <div class="card-body p-2">
              <img alt="Image placeholder" src="assets/1.png"
                   class="img-fluid shadow rounded">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-6">
      <div class="row row-grid justify-content-between align-items-center">
        <div class="col-lg-5">
          <h5 class="h3">Възможст за задаване на въпроси</h5>
          <p class="lead my-4">
            На всеки ваш въпрос ще бъде отговорено с отговорност и професионализъм от магистър-фармацевт.
          </p>
          <ul class="list-unstyled mb-0">
            <li class="py-2">
              <div class="d-flex align-items-center">
                <div>
                  <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3">
                    <i class="fas fa-file-invoice"></i>
                  </div>
                </div>
                <div>
                  <span class="h6 mb-0">Връзка с наш специалист</span>
                </div>
              </div>
            </li>
            <li class="py-2">
              <div class="d-flex align-items-center">
                <div>
                  <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3">
                    <i class="fas fa-store-alt"></i>
                  </div>
                </div>
                <div>
                  <span class="h6 mb-0">Онлайн чат</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-lg-6">
          <div class="card mb-0 ml-lg-5">
            <div class="card-body p-2">
              <img alt="Image placeholder" src="assets/2.png"
                   class="img-fluid shadow rounded">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="slice slice-lg bg-section-secondary" id="price">
  <div class="container text-center">
    <div class="row justify-content-center mb-6">
      <div class="col-lg-8">
        <!-- Title -->
        <h2 class="h1 strong-600">
          Сравни! Направи своя избор!
        </h2>
        <!-- Text -->
        <p class="text-muted">
          Ще имаш достъп до статии на нашия сайт.
        </p>
      </div>
      <h4 style="color: red; font-weight: bold" class="mb-5" *ngIf="freePlan">Можеш да заявиш 10-дневен пробен период</h4>

    </div>
    <!-- Pricing -->
    <!--        <div class="row justify-content-center">-->
    <!--            <div class="col-lg-4 col-md">-->
    <!--                <div class="card card-pricing text-center px-3 hover-scale-110">-->
    <!--                    <div class="card-header py-5 border-0 delimiter-bottom">-->
    <!--                        <div class="h1 text-center mb-0">BGN<span class="price font-weight-bolder">5.49</span></div>-->
    <!--                        <span class="h6 text-muted">План сребро</span>-->
    <!--                    </div>-->
    <!--                    <div class="card-body">-->
    <!--                        <ul class="list-unstyled text-sm mb-4">-->
    <!--                            <li class="py-2">достъп до 3 статии</li>-->
    <!--                            <li class="py-2">2 въпроса с отговор до 12часа</li>-->
    <!--                        </ul>-->
    <!--                        <a href="#" class="btn btn-sm btn-warning hover-translate-y-n3 hover-shadow-lg mb-3">Purchase now</a>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div class="col-lg-4 col-md">-->
    <!--                <div class="card card-pricing bg-dark text-center px-3 border-0 hover-scale-110">-->
    <!--                    <div class="card-header py-5 border-0 delimiter-bottom">-->
    <!--                        <div class="h1 text-white text-center mb-0">BGN<span class="price font-weight-bolder">19.99</span></div>-->
    <!--                        <span class="h6 text-white">План платина</span>-->
    <!--                    </div>-->
    <!--                    <div class="card-body">-->
    <!--                        <ul class="list-unstyled text-white text-sm opacity-8 mb-4">-->
    <!--                            <li class="py-2">достъп до всички статии</li>-->
    <!--                            <li class="py-2">10 въпроса с отговор до 6часа</li>-->
    <!--                            <li class="py-2">5% отстъпка в аптеки Кактус</li>-->
    <!--                        </ul>-->
    <!--                        <a href="#" class="btn btn-sm btn-white hover-translate-y-n3 hover-shadow-lg mb-3">Purchase now</a>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div class="col-lg-4 col-md">-->
    <!--                <div class="card card-pricing text-center px-3 hover-scale-110">-->
    <!--                    <div class="card-header py-5 border-0 delimiter-bottom">-->
    <!--                        <div class="h1 text-center mb-0">BGN<span class="price font-weight-bolder">12.39</span></div>-->
    <!--                        <span class="h6 text-muted">План злато</span>-->
    <!--                    </div>-->
    <!--                    <div class="card-body">-->
    <!--                        <ul class="list-unstyled text-sm mb-4">-->
    <!--                            <li class="py-2">достъп до 10 статии</li>-->
    <!--                            <li class="py-2">5 въпроса с отговор до 12часа</li>-->
    <!--                        </ul>-->
    <!--                        <a href="#" class="btn btn-sm btn-warning hover-translate-y-n3 hover-shadow-lg mb-3">Purchase now</a>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md">

        <div class="card card-pricing text-center px-3 hover-scale-110">
          <div class="card-header py-5 border-0 delimiter-bottom">
            <span class="h1" style="color:#006ecc">План сребро</span>
            <div class="h5 text-center mb-0">BGN<span class="price font-weight-bolder">5.39</span></div>
            <span class="h6">= 2 прегледа при личен лекар.</span>
          </div>
          <div class="card-body">
            <h3>Получаваш: </h3>
            <ul class="list-unstyled text-sm mb-4">
              <li class="py-2">достъп до 3 статии</li>
              <li class="py-2">2 въпроса с отговор до 12часа</li>
            </ul>
            <div id="smart-button-container">
              <div style="text-align: center;">
                <div id="paypal-button-container"></div>
              </div>
            </div>
            <a [routerLink]="isLogin ? '/prices': '/user/register'"
               class="btn btn-sm btn-primary hover-translate-y-n3 hover-shadow-lg mb-3">Активирай</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md">
        <div class="card card-pricing bg-dark-yellow text-center px-3 border-0 hover-scale-110">
          <div class="card-header py-5 border-0 delimiter-bottom pl-0 pr-0">
            <!--          <p class="text-white m-0 p-0">най-предпочитан</p>-->
            <span class="h1" style="color:white;">План платина</span>
            <div class="h5 text-white text-center mb-0">BGN<span class="price font-weight-bolder">23.89</span></div>
            <span class="h6" style="color:white;">= 8 прегледа при личен лекар.</span>
          </div>
          <div class="card-body">
            <h3 style="color: white">Получаваш: </h3>
            <ul class="list-unstyled text-white text-sm opacity-8 mb-4">
              <li class="py-2">достъп до всички статии</li>
              <li class="py-2">онлайн чат в работно време</li>
              <li class="py-2">5% отстъпка в аптеки Кактус</li>
            </ul>
            <a [routerLink]="isLogin ? '/prices': '/user/register'"
               class="btn btn-sm btn-white hover-translate-y-n3 hover-shadow-lg mb-3">Активирай</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md">
        <div class="card card-pricing text-center px-3 hover-scale-110">
          <div class="card-header py-5 border-0 delimiter-bottom">
            <span class="h1" style="color:#006ecc">План злато</span>
            <div class="h5 text-center mb-0">BGN<span class="price font-weight-bolder">13.99</span></div>
            <span class="h6">= 5 прегледа при личен лекар.</span>
          </div>
          <div class="card-body">
            <h3>Получаваш: </h3>
            <ul class="list-unstyled text-sm mb-4">
              <li class="py-2">достъп до 10 статии</li>
              <li class="py-2">5 въпроса с отговор до 12часа</li>
            </ul>
            <a [routerLink]="isLogin ? '/prices': '/user/register'"
               class="btn btn-sm btn-primary hover-translate-y-n3 hover-shadow-lg mb-3">Активирай</a>
          </div>
        </div>
      </div>
    </div>
    <table class="table">
      <thead>
      <tr>
        <th>#</th>
        <th>План сребро</th>
        <th>План платина</th>
        <th>План злато</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Достъп до статии</td>
        <td>3</td>
        <td>неограничен брой</td>
        <td>10</td>
      </tr>
      <tr>
        <td>Задай въпрос на фармацевт</td>
        <td>2</td>
        <td>неограничен брой</td>
        <td>5</td>
      </tr>
      <tr>
        <td>Личен фармацевт</td>
        <td>не</td>
        <td>да</td>
        <td>не</td>
      </tr>
      <tr>
        <td>Онлайн чат</td>
        <td>не</td>
        <td>да</td>
        <td>да</td>
      </tr>
      <tr>
        <td>Предлагане на статия</td>
        <td>не</td>
        <td>да</td>
        <td>не</td>
      </tr>
      <tr class="bold">
        <td>Цена</td>
        <td>5.39</td>
        <td>23.89</td>
        <td>13.99</td>
      </tr>
      </tbody>
    </table>
    <div class="mt-5 text-center">
      <p class="mb-2">
        Всички цени са на месец. Ако имате нужда от повече акаунти свържете се с нас за оферта.
      </p>
      <p class="mb-2">
        Възможност за плащане чрез paypal и банков превод.
      </p>
      <a routerLink="/contact" class="text-primary text-underline--dashed">Контакти<i data-feather="arrow-right"
                                                                                      class="ml-2"></i></a>
    </div>
  </div>
</section>
