<section class="slice slice-lg pt-lg-6 pb-0 pb-lg-6 bg-section-secondary" id="about-us">
    <div class="container">
        <div class="row mb-5 justify-content-center text-center">
            <div class="col-lg-6">
                <h2 class="mt-4">Контакти</h2>
               
            </div>
        </div>
        <p>Email: mail@pharmacy.com</p>
        <h3>Изпрати запитване</h3>
        <form #form="ngForm" (ngSubmit)="submitHandler(form.form.value)">
            <div class="form-errors mb-3">
                <p class="mb-0" *ngIf="email.touched && email.invalid">Email адреса e невалиден</p>
                <p class="mb-0" *ngIf="name.touched && name.invalid">Името трябва да бъде поне 3 символа</p>
                <p class="mb-0" *ngIf="message.touched && message.invalid">Съобщението трябва да бъде поне 50 символа</p>
            </div>
            <div class="form-group">
                <label class="form-control-label ">Email:</label>
                <div class="input-group ">
                    <div class="input-group-prepend ">
                        <span class="input-group-text "><i class="fas fa-envelope "></i></span>
                    </div>
                    <input type="email" class="form-control" id="input-email" name="email" placeholder="name@example.com" #email="ngModel" ngModel required email/>
                </div>
            </div>
            <div class="form-group">
                <label class="form-control-label ">Име:</label>
                <div class="input-group ">
                    <div class="input-group-prepend ">
                        <span class="input-group-text "><i class="fas fa-user "></i></span>
                    </div>
                    <input type="email" class="form-control" id="input-name" name="name" placeholder="Име" #name="ngModel" ngModel required minlength="3" />
                </div>
            </div>
            <div class="form-group">
                <label class="form-control-label ">Съобщение / Въпрос:</label>
                <div class="input-group ">
                    <textarea name="message" id="message" cols="30" rows="10" class="form-control" #message="ngModel" ngModel required minlength="50"></textarea>
                </div>
            </div>
            <button type="submit" class="btn btn-block btn-primary" style="width: 20%;">Изпрати</button>
        </form>
    </div>
</section>
