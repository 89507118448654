import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UserService } from '../../user/user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoggedActivateGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.authCompletedSingle$.pipe(map(user => !user.hasOwnProperty('error')), catchError((err) => {
      return this.router.navigate(['/user/login']);
    }));
  }

}
