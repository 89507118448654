import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ToastService, AngularToastifyModule} from 'angular-toastify';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {HomePageComponent} from './home-page/home-page.component';
import {CoreModule} from './core/core.module';
import {AboutUsComponent} from './about-us/about-us.component';
import {ServicesPageComponent} from './services-page/services-page.component';
import {PricesComponent} from './prices/prices.component';
import {ContactPageComponent} from './contact-page/contact-page.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {DefaultInterceptor} from "./default.interceptor";
import {FormsModule} from "@angular/forms";
import {NgxPayPalModule} from 'ngx-paypal';
import {SharedModule} from "./shared/shared.module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TermsComponent } from './terms/terms.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    HomePageComponent,
    AboutUsComponent,
    ServicesPageComponent,
    PricesComponent,
    ContactPageComponent,
    TermsComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        HttpClientModule,
        FormsModule,
        AngularToastifyModule,
        NgxPayPalModule,
        SharedModule,
        BrowserAnimationsModule
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultInterceptor,
      multi: true
    },
    ToastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
