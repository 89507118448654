import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PriceService {
  constructor(private http: HttpClient) {
  }

  getPriceById(id: Number | undefined) {
    return this.http.get('price/' + id);
  }

  getAll() {
    return this.http.get('price');
  }

  setPlan(planId, token, name, price) {
    return this.http.post('plan', {plan: planId, token, name, price});
  }

  freeTrial() {
    return this.http.post('free-trial', {});
  }
}
