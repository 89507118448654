import {Component, OnInit} from '@angular/core';
import {UserService} from '../user/user.service';
import {delay} from "rxjs/operators";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  hasAccess;
  freePlan;
  userName;

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.userService.authCompletedSingle$.subscribe(user => {
      this.hasAccess = user.active_plan !== 0;
      this.freePlan = user.free_plan === 0;
      this.userName = user.real_name;
    });
  }

}
