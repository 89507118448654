import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {RouterModule} from '@angular/router';
import {FooterComponent} from './footer/footer.component';
import {FormatDatePipe} from './pipes/format-date.pipe';
import {PricingPlanPipe} from './pipes/pricing-plan.pipe';
import {PaypalComponent} from './paypal/paypal.component';
import {NgxPayPalModule} from "ngx-paypal";
import { ShortenPipe } from './pipes/shorten.pipe';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    FormatDatePipe,
    PricingPlanPipe,
    PaypalComponent,
    ShortenPipe,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPayPalModule
  ],
    exports: [HeaderComponent, FooterComponent, FormatDatePipe, PricingPlanPipe, PaypalComponent, ShortenPipe]
})
export class CoreModule {
}
