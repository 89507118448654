<section class="slice slice-lg pt-lg-6 pb-0 pb-lg-6 bg-section-secondary" id="about-us">
  <div class="container" [ngStyle]="{height: hasAccess == null ? '700px' : ''}">
    <!-- Title -->
    <app-loader *ngIf="hasAccess == null"></app-loader>
    <!-- Section title -->
    <div class="row mb-0 justify-content-center text-center" *ngIf="hasAccess !== undefined">
      <div class="col-lg-6">
        <!-- <span class="badge badge-soft-success badge-pill badge-lg">
            Welcome
        </span> -->
        <h1>Здравей, {{userName}}</h1>
        <ng-container *ngIf="hasAccess">
          <h2 class="mt-4 mb-0">Избери</h2>
          <div class="mt-0">
            <p class="lead lh-180">
              от посочените услуги
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="!hasAccess">
          <h2 class="mt-4 mb-0">За съжаление все още нямаш достъп, понеже не си активирал план</h2>
          <div class="mt-0">
            <p class="lead lh-180">
              избери от по-долу
            </p>
          </div>
          <h4 style="color: red; font-weight: bold" class="mb-0" *ngIf="freePlan">Можеш да заявиш 10-дневен пробен период</h4>
        </ng-container>
      </div>
    </div>
    <!-- Card -->
    <div class="row mt-5" *ngIf="hasAccess && hasAccess != null">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body pb-5" routerLink="/article">
            <div class="pt-4 pb-5">
              <img src="assets/img/svg/illustrations/illustration-5.svg" class="img-fluid img-center"
                   style="height: 150px;" alt="Illustration"/>
            </div>
            <h5 class="h4 lh-130 mb-3">Статии</h5>
            <p class="text-muted mb-0">
              Нашити статии са подходящи както за пациенти, така и за фармацевти, лекари и студенти.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body pb-5" routerLink="/place-question">
            <div class="pt-4 pb-5">
              <img src="assets/img/svg/illustrations/illustration-6.svg" class="img-fluid img-center"
                   style="height: 150px;" alt="Illustration"/>
            </div>
            <h5 class="h4 lh-130 mb-3">Задай въпрос на фармацевт</h5>
            <p class="text-muted mb-0">На всеки въпрос ще бъде отговорено с отговорност и професионализъм от
              магистър-фармацевт.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body pb-5">
            <div class="pt-4 pb-5">
              <img src="assets/img/svg/illustrations/illustration-7.svg" class="img-fluid img-center"
                   style="height: 150px;" alt="Illustration"/>
            </div>
            <h5 class="h4 lh-130 mb-3">Онлайн чат</h5>
            <p class="text-muted mb-0">Скоро</p>
          </div>
        </div>
      </div>
    </div>

    <app-prices *ngIf="!hasAccess && hasAccess != null" [hasTitle]="false"></app-prices>

    <p style="text-align: justify" *ngIf="hasAccess != null">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero ratione expedita voluptates quos repudiandae
      nulla mollitia magnam officiis eaque quaerat aliquam quibusdam aliquid obcaecati ipsam, ex veritatis beatae
      eius dignissimos doloribus
      ea nostrum asperiores! Assumenda laudantium dolorem quia quos vero. Porro expedita labore ipsa modi doloribus.
      Excepturi vel necessitatibus consequatur. Veniam minus iure quasi dolorum quidem nisi cum obcaecati nostrum
      deserunt eum! Debitis
      animi, dolorum qui obcaecati facilis accusamus molestiae minima laborum. Alias beatae iure error sit veritatis
      distinctio recusandae quod sapiente, corporis ratione explicabo rerum aliquam fuga vel deleniti ipsa totam
      accusantium facere nam
      velit? Earum animi vel quibusdam.
    </p>
  </div>
</section>
