import {Component, OnInit} from '@angular/core';
import {ToastService} from "angular-toastify";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'frontend-cactus';

  constructor(private toastService: ToastService) {
  }

  ngOnInit() {
  }

}

