<section class="slice slice-lg pt-lg-6 pb-0 pb-lg-6 bg-section-secondary" id="services">
    <div class="container">
        <div class="row mb-5 justify-content-center text-center">
            <div class="col-lg-6">
                <!-- <span class="badge badge-soft-success badge-pill badge-lg">
                    Welcome
                </span> -->
                <h2 class="mt-4">Услуги</h2>

            </div>
        </div>
        <div class="mt-5">
            <div class="row row-grid justify-content-between align-items-center">
                <div class="col-lg-5 order-lg-2">
                    <h5 class="h3">Достъп до статии</h5>
                    <p class="lead my-4">
                        Нашити статии са подходящи както за пациенти, така и за фармацевти, лекари и студенти.
                    </p>
                    <ul class="list-unstyled mb-0">
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3">
                                        <i class="fas fa-file-invoice"></i>
                                    </div>
                                </div>
                                <div>
                                    <span class="h6 mb-0">Нова статия през ден</span>
                                </div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3">
                                        <i class="fas fa-store-alt"></i>
                                    </div>
                                </div>
                                <div>
                                    <span class="h6 mb-0">Интервюта с лекари</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6 order-lg-1">
                    <div class="card mb-0 mr-lg-5">
                        <div class="card-body p-2">
                            <img alt="Image placeholder" src="assets/1.png" class="img-fluid shadow rounded">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-6">
            <div class="row row-grid justify-content-between align-items-center">
                <div class="col-lg-5">
                    <h5 class="h3">Възможст за задаване на въпроси</h5>
                    <p class="lead my-4">
                        На всеки ваш въпрос ще бъде отговорено с отговорност и професионализъм от магистър-фармацевт.
                    </p>
                    <ul class="list-unstyled mb-0">
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3">
                                        <i class="fas fa-file-invoice"></i>
                                    </div>
                                </div>
                                <div>
                                    <span class="h6 mb-0">Връзка с наш специалист</span>
                                </div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3">
                                        <i class="fas fa-store-alt"></i>
                                    </div>
                                </div>
                                <div>
                                    <span class="h6 mb-0">Онлайн чат</span>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
                <div class="col-lg-6">
                    <div class="card mb-0 ml-lg-5">
                        <div class="card-body p-2">
                            <img alt="Image placeholder" src="assets/2.png" class="img-fluid shadow rounded">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
