import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: string | undefined, showHoursAndMinutes: boolean = false): string {
    if (value === undefined) {
      return '';
    }

    value = value.split(' ').join('T');

    const dateObject = new Date(value);
    const date = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();

    if (showHoursAndMinutes) {
      const hours = dateObject.getHours();
      const minutes = dateObject.getUTCMinutes();

      return `${date.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year} ${hours}:${minutes.toString().padStart(2, '0')}`;
    }

    return `${date}.${month}.${year}`;
  }

}
